import {Component, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom"
import {
    Input,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,
    Tag, Button, Statistic
} from "antd";
import Apiurl from "../ConstURL";
import Hosturl from "../ConstURL";

import {format} from 'date-fns'

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, PlusCircleOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";



export default function Mainpage() {
    let params = useParams()
    const Navigate = useNavigate()
    const [Chats_massive, Set_Chats_massive] = useState([])



    function DeleteElementYess(id) {
        const idx = Chats_massive.findIndex((el) => el.Id === id)
        let newusers = Chats_massive.slice()
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        Set_Chats_massive(newusers)

        fetch(Apiurl + "/chats/delete?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            chatid: id
        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                if (data.Status){
                    let newusers = Chats_massive.slice()
                    console.log(newusers)
                    newusers.splice(idx,1)
                    console.log(newusers)
                    Set_Chats_massive(newusers)
                    message.success("Чат удален!")
                }else {
                    message.error("Чат не удален!")
                }

            })




    }
    function DeleteElementYesorNo(id) {

        const idx = Chats_massive.findIndex((el) => el.Id === id)
        let newusers = Chats_massive.slice()
        console.log(newusers[idx].DeleteYesorNo)
        newusers[idx].DeleteYesorNo = !newusers[idx].DeleteYesorNo
        console.log(newusers[idx].DeleteYesorNo)
        Set_Chats_massive(newusers)



    }

    useEffect(() => {
        fetch(Apiurl + "/clientswithstatics/get?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: 0,
            limit: 100,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_Chats_massive(data.Data)
                //console.log(data.Data)
            })


    },[]);


    return (<div>
            <Header/>


            <Flex vertical={false} justify={"center"} style={{paddingTop: "7px"}}>

                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>

                    {Chats_massive.map((Chat, index) => (
                        <Card title={Chat.Name} style={{width: "75vw"}} >
                            <Flex vertical={true} gap={"small"}>
                                <div><b>Id клиента:</b> {Chat.Id}</div>

                                <Flex style={{paddingTop:15}}  gap={50}>
                                    <Statistic title="Закрытых" value={Chat.Closed_tickets} valueStyle={{color:"#47ff00"}}/>
                                    <Statistic title="В работе" value={Chat.Inwork_tickets} valueStyle={{color:"#ff9201"}}/>
                                    <Statistic title="Ожидают ответа" value={Chat.Open_tickets} valueStyle={{color:"#ff3c3c"}}/>
                                </Flex>

                            </Flex>
                        </Card>
                    ))}
                </Space>


            </Flex>


        </div>
    );

}