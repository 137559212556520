import {Component, useEffect, useState} from "react";

import {
    Input,
    DatePicker,
    Card,
    Image,
    Flex,
    Pagination,
    Row,
    Typography,
    Space,
    Progress,
    message,
    Collapse,
    Select,

    Popconfirm, Button, Divider, Modal, Checkbox
} from "antd";
import Apiurl from "../ConstURL";
import {format} from 'date-fns'
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import {
    CheckOutlined, CloseOutlined,
    FileExcelOutlined, MinusOutlined, DeleteOutlined, SendOutlined,
} from '@ant-design/icons';

import Header from "../components/Header";
import {useAuth} from "../funcs/Authcontext";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";

const {Text, Link} = Typography;
const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
};
const disabledRangeTime = (_, type) => {
    if (type === 'start') {
        return {
            disabledSeconds: () => [55, 56],
        };
    }
    return {
        disabledSeconds: () => [55, 56],
    };
};
const {RangePicker} = DatePicker;
export default function Mainpage() {

    const [alldopclients, setalldopclients] = useState([])

    const [tasks, settasks] = useState([])
    const [taskscount, settaskscount] = useState(0)


    const [filter_workerid, Set_filter_workerid] = useState("")
    const [filter_chatid, Set_filter_chatid] = useState("")
    const [filter_client_dop, Set_filter_client_dop] = useState("")
    const [filter_insideid, Set_filter_insideid] = useState("")
    const [filter_type, Set_filter_type] = useState("")
    const [filter_search_text, Set_filter_search_text] = useState("")
    const [filter_status, Set_filter_status] = useState("0")
    const [time_from, Set_time_from] = useState("")
    const [time_to, Set_time_to] = useState("")
    const [time_offset, Set_time_offset] = useState("")
    const [filter_filter, Set_filter_filter] = useState("")

    const [poup_answer, Set_poup_answer] = useState(false)
    const [text_input_popup, Set_text_input_popup] = useState("")
    const [id_ticket_close, Set_id_ticket_close] = useState("")

    const [comment_text_dict, Set_comment_text_dict] = useState({})

    const [chats_massive, Set_chats_massive] = useState([])
    const [workers_massive, Set_workers_massive] = useState([])
    const [users_massive, Set_users_massive] = useState({})
    const [types_massive, Set_types_massive] = useState([{"Id": "1", "Name": "Успешно"}, {
        "Id": "2",
        "Name": "Другое"
    }, {"Id": "3", "Name": "Отклонено"}])

    const [types_dop_massive, Set_types_dop_massive] = useState([])

    const [pagenum, set_pagenum] = useState(1)

    const rangePresets = [
        {
            label: 'За 7 дней',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'За 14 дней',
            value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
            label: 'За 30 дней',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'За 90 дней',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ];

    const UpdateInfoTickets = (Skip = (1 - 1) * 10, Limit = 10, Filter_workerid = filter_workerid, Filter_chatid = filter_chatid, Filter_insideid = filter_insideid, Filter_type = filter_type, Filter_status = filter_status, Filter_search_text = filter_search_text, Filter_filter = filter_filter, Time_from = time_from, Time_to = time_to, Filter_client_dop = filter_client_dop) => {
        fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: Skip,
            limit: Limit,
            filter_workerid: Filter_workerid,
            filter_chatid: Filter_chatid,
            filter_client_dop: Filter_client_dop,
            filter_insideid: Filter_insideid,
            filter_type: Filter_type,
            filter_status: Filter_status,
            filter_search_text: Filter_search_text,
            filter_sort: Filter_filter,
            from_time: Time_from,
            to_time: Time_to,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })
    }

    const CloseOpenPopup = (ticket_idd) => {
        Set_id_ticket_close(ticket_idd)
        Set_text_input_popup("")
        Set_poup_answer(!poup_answer)
    }

    const handleChangeInput = (e) => {
        const {name, value} = e.target;

        Set_text_input_popup(value)
    };
    const Closeticketdope = () => {
        var ticketid = id_ticket_close
        if (text_input_popup === "") {
            message.error("Введите комментарий!");
            return
        }
        CloseOpenPopup()
        fetch(Apiurl + "/ticketweb/close?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,
            close_type: 2,
            message: text_input_popup,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                        undefined, undefined, undefined, undefined, undefined,
                        undefined, undefined)
                    // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                    //     token: localStorage.getItem("token"),
                    //     skip: (pagenum - 1) * 10,
                    //     limit: 10,
                    //     filter_workerid: filter_workerid,
                    //     filter_chatid: filter_chatid,
                    //     filter_insideid: filter_insideid,
                    //     filter_status: filter_status,
                    //     from_time: time_from,
                    //     filter_search_text: filter_search_text,
                    //     filter_sort: filter_filter,
                    //     to_time: time_to,
                    //     time_offset: new Date().getTimezoneOffset().toString(),
                    //
                    // }))
                    //     .then(response => {
                    //         return response.json()
                    //     })
                    //     .then(data => {
                    //         settaskscount(data.Allcount)
                    //         settasks(data.Data)
                    //         message.success("Тикет " + ticketid + " закрыт");
                    //     })
                } else {
                    message.error("Тикет " + ticketid + " не закрыт!");
                }
            })


    };
    const CloseticketUspeh = (ticketid, type) => {

        fetch(Apiurl + "/ticketweb/close?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,
            close_type: type,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                        undefined, undefined, undefined, undefined, undefined,
                        undefined, undefined)
                    // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                    //     token: localStorage.getItem("token"),
                    //     skip: (pagenum - 1) * 10,
                    //     limit: 10,
                    //     filter_workerid: filter_workerid,
                    //     filter_chatid: filter_chatid,
                    //     filter_insideid: filter_insideid,
                    //     filter_status: filter_status,
                    //     from_time: time_from,
                    //     filter_sort: filter_filter,
                    //     filter_search_text: filter_search_text,
                    //     to_time: time_to,
                    //     time_offset: new Date().getTimezoneOffset().toString(),
                    //
                    // }))
                    //     .then(response => {
                    //         return response.json()
                    //     })
                    //     .then(data => {
                    //         settaskscount(data.Allcount)
                    //         settasks(data.Data)
                    //         message.success("Тикет " + ticketid + " закрыт");
                    //     })
                } else {
                    message.error("Тикет " + ticketid + " не закрыт!");
                }
            })


    };


    const reopenticket = (ticketid) => {

        fetch(Apiurl + "/ticketweb/reopen?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                        undefined, undefined, undefined, undefined, undefined,
                        undefined, undefined)
                    // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                    //     token: localStorage.getItem("token"),
                    //     skip: (pagenum - 1) * 10,
                    //     limit: 10,
                    //     filter_workerid: filter_workerid,
                    //     filter_chatid: filter_chatid,
                    //     filter_insideid: filter_insideid,
                    //     filter_status: filter_status,
                    //     from_time: time_from,
                    //     filter_search_text: filter_search_text,
                    //     filter_sort: filter_filter,
                    //     to_time: time_to,
                    //     time_offset: new Date().getTimezoneOffset().toString(),
                    //
                    // }))
                    //     .then(response => {
                    //         return response.json()
                    //     })
                    //     .then(data => {
                    //         settaskscount(data.Allcount)
                    //         settasks(data.Data)
                    //         message.success("Тикет " + ticketid + " переоткрыт");
                    //     })
                } else {
                    message.error("Тикет " + ticketid + " не переоткрыт!");
                }
            })


    };

    const confirmclose = (ticketid) => {
        console.log(ticketid);

        fetch(Apiurl + "/ticketsweb/close?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                        undefined, undefined, undefined, undefined, undefined,
                        undefined, undefined)
                    // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                    //     token: localStorage.getItem("token"),
                    //     skip: (pagenum - 1) * 10,
                    //     limit: 10,
                    //     filter_workerid: filter_workerid,
                    //     filter_chatid: filter_chatid,
                    //     filter_insideid: filter_insideid,
                    //     filter_status: filter_status,
                    //     from_time: time_from,
                    //     filter_search_text: filter_search_text,
                    //     filter_sort: filter_filter,
                    //     to_time: time_to,
                    //     time_offset: new Date().getTimezoneOffset().toString(),
                    //
                    // }))
                    //     .then(response => {
                    //         return response.json()
                    //     })
                    //     .then(data => {
                    //         settaskscount(data.Allcount)
                    //         settasks(data.Data)
                    //         message.success("Тикет " + ticketid + " закрыт");
                    //     })
                } else {
                    message.error("Тикет " + ticketid + " не закрыт!");
                }
            })


    };

    const confirmdelete = (ticketid) => {
        console.log(ticketid);

        fetch(Apiurl + "/ticketsweb/delete?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                        undefined, undefined, undefined, undefined, undefined,
                        undefined, undefined)
                    // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                    //     token: localStorage.getItem("token"),
                    //     skip: (pagenum - 1) * 10,
                    //     limit: 10,
                    //     filter_workerid: filter_workerid,
                    //     filter_chatid: filter_chatid,
                    //     filter_insideid: filter_insideid,
                    //     filter_status: filter_status,
                    //     from_time: time_from,
                    //     filter_sort: filter_filter,
                    //     filter_search_text: filter_search_text,
                    //     to_time: time_to,
                    //     time_offset: new Date().getTimezoneOffset().toString(),
                    //
                    // }))
                    //     .then(response => {
                    //         return response.json()
                    //     })
                    //     .then(data => {
                    //         settaskscount(data.Allcount)
                    //         settasks(data.Data)
                    //         message.success("Тикет " + ticketid + " удален");
                    //     })
                } else {
                    message.error("Тикет " + ticketid + " не удален!");
                }
            })


    };
    const onChangeFilterfilter_date = (text) => {
        console.log(new Date().getTimezoneOffset())
        if (text) {
            console.log(text[0].format('YYYY-MM-DD HH:mm'))
            console.log(text[1].format('YYYY-MM-DD HH:mm'))
            Set_time_from(text[0].format('YYYY-MM-DD HH:mm'))
            Set_time_to(text[1].format('YYYY-MM-DD HH:mm'))
            Set_time_offset(new Date().getTimezoneOffset().toString())

            UpdateInfoTickets(undefined, undefined, undefined, undefined, undefined,
                undefined, undefined, undefined, undefined, text[0].format('YYYY-MM-DD HH:mm'),
                text[1].format('YYYY-MM-DD HH:mm'), undefined)

            // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
            //     token: localStorage.getItem("token"),
            //     skip: (1 - 1) * 10,
            //     limit: 10,
            //     filter_workerid: filter_workerid,
            //     filter_chatid: filter_chatid,
            //     filter_insideid: filter_insideid,
            //     filter_type: filter_type,
            //     filter_status: filter_status,
            //     filter_search_text: filter_search_text,
            //     filter_sort: filter_filter,
            //     from_time: text[0].format('YYYY-MM-DD HH:mm'),
            //     to_time: text[1].format('YYYY-MM-DD HH:mm'),
            //     time_offset: new Date().getTimezoneOffset().toString(),
            //
            // }))
            //     .then(response => {
            //         return response.json()
            //     })
            //     .then(data => {
            //         console.log(data.Allcount)
            //         settaskscount(data.Allcount)
            //         settasks(data.Data)
            //     })

        } else {
            Set_time_from("")
            Set_time_to("")

            UpdateInfoTickets(undefined, undefined, undefined, undefined, undefined,
                undefined, undefined, undefined, undefined, "",
                "", undefined)

            // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
            //     token: localStorage.getItem("token"),
            //     skip: (1 - 1) * 10,
            //     limit: 10,
            //     filter_workerid: filter_workerid,
            //     filter_chatid: filter_chatid,
            //     filter_insideid: filter_insideid,
            //     filter_type: filter_type,
            //     filter_status: filter_status,
            //     filter_search_text: filter_search_text,
            //     filter_sort: filter_filter,
            //     from_time: "",
            //     to_time: "",
            //     time_offset: new Date().getTimezoneOffset().toString(),
            //
            // }))
            //     .then(response => {
            //         return response.json()
            //     })
            //     .then(data => {
            //         console.log(data.Allcount)
            //         settaskscount(data.Allcount)
            //         settasks(data.Data)
            //     })
        }
    };
    const onChangeFilterWorkerId = (text) => {
        if (text) {
            text = text
        } else {
            text = ""
        }

        Set_filter_workerid(text)
        set_pagenum(1)

        UpdateInfoTickets(undefined, undefined, text, undefined, undefined,
            undefined, undefined, undefined, undefined, undefined,
            undefined, undefined)
        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (1 - 1) * 10,
        //     limit: 10,
        //     filter_workerid: text,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: filter_insideid,
        //     filter_type: filter_type,
        //     filter_status: filter_status,
        //     filter_search_text: filter_search_text,
        //     filter_sort: filter_filter,
        //     from_time: time_from,
        //     to_time: time_to,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

    };

    const onChangeFilterfilter_chatid_dop = (text) => {
        if (text === undefined) {
            text = ""
        }
        Set_filter_client_dop(text)
        set_pagenum(1)

        UpdateInfoTickets(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, text)


    };

    const onChangeFilterfilter_chatid = (text) => {
        if (text === undefined) {
            text = ""
        }
        Set_filter_chatid(text)
        set_pagenum(1)


        fetch(Apiurl + "/ticketweb/clientsdop/get/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            clientid: text,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                setalldopclients(data.Data)
            })

        UpdateInfoTickets(undefined, undefined, undefined, text, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)

        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (1 - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: text,
        //     filter_insideid: filter_insideid,
        //     filter_type: filter_type,
        //     filter_status: filter_status,
        //     filter_search_text: filter_search_text,
        //     filter_sort: filter_filter,
        //     from_time: time_from,
        //     to_time: time_to,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

    };
    const onChangeFilterfilter_insideid = (text) => {
        text = text.target.value
        Set_filter_insideid(text)
        set_pagenum(1)

        UpdateInfoTickets(undefined, undefined, undefined, undefined, text,
            undefined, undefined, undefined, undefined, undefined,
            undefined, undefined)


        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (1 - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: text,
        //     filter_type: filter_type,
        //     filter_status: filter_status,
        //     filter_search_text: filter_search_text,
        //     filter_sort: filter_filter,
        //     from_time: time_from,
        //     to_time: time_to,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

    };

    // function UpdateFiltersTickets(){
    //     fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
    //         token: localStorage.getItem("token"),
    //         skip: (1 - 1) * 10,
    //         limit: 10,
    //         filter_workerid: filter_workerid,
    //         filter_chatid: filter_chatid,
    //         filter_insideid: filter_insideid,
    //         filter_type: filter_type,
    //         filter_status: filter_status,
    //         filter_search_text: filter_search_text,
    //         from_time: time_from,
    //         to_time: time_to,
    //         time_offset: new Date().getTimezoneOffset().toString(),
    //
    //     }))
    //         .then(response => {
    //             return response.json()
    //         })
    //         .then(data => {
    //             console.log(data.Allcount)
    //             settaskscount(data.Allcount)
    //             settasks(data.Data)
    //         })
    // }
    const onChangeFilterfilter_filter = (text) => {
        console.log(text)
        text = text.target.checked
        if (text) {
            text = "1"
        } else {
            text = ""
        }
        // setTimeout(() => {  console.log("World!"); }, 1000);
        Set_filter_filter(text)

        UpdateInfoTickets(undefined, undefined, undefined, undefined, undefined,
            undefined, undefined, undefined, text, undefined,
            undefined, undefined)
        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (1 - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: filter_insideid,
        //     filter_type: filter_type,
        //     filter_status: filter_status,
        //     filter_search_text: filter_search_text,
        //     from_time: time_from,
        //     to_time: time_to,
        //     filter_sort: text,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

        set_pagenum(1)

        //UpdateFiltersTickets();

    };

    const onChangeFilterfilter_insearchtext = (text) => {
        text = text.target.value
        console.log(text)
        // setTimeout(() => {  console.log("World!"); }, 1000);
        if (text === "") {
            Set_filter_search_text("")
        } else {
            Set_filter_search_text(text)
        }
        UpdateInfoTickets(undefined, undefined, undefined, undefined, undefined,
            undefined, undefined, text, undefined, undefined,
            undefined, undefined)

        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (1 - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: filter_insideid,
        //     filter_type: filter_type,
        //     filter_status: filter_status,
        //     filter_search_text: text,
        //     from_time: time_from,
        //     to_time: time_to,
        //     filter_sort: filter_filter,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

        set_pagenum(1)

        //UpdateFiltersTickets();

    };
    const onChangeFilterfilter_status = (text) => {
        Set_filter_status(text)
        set_pagenum(1)

        UpdateInfoTickets(undefined, undefined, undefined, undefined, undefined,
            undefined, text, undefined, undefined, undefined,
            undefined, undefined)
        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (1 - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: filter_insideid,
        //     filter_type: filter_type,
        //     filter_status: text,
        //     filter_search_text: filter_search_text,
        //     from_time: time_from,
        //     to_time: time_to,
        //     filter_sort: filter_filter,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

    };

    const onChangeDopType_status = (text, ticketid) => {
        //Set_filter_status(text)
        fetch(Apiurl + "/tickets/set/doptype?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,
            typedop: text,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data)
            })

        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (pagenum - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: filter_insideid,
        //     filter_type: filter_type,
        //     filter_status: filter_status
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

    };

    const onChangeFilterType_change = (text) => {
        if (text === undefined) {
            text = ""
        }
        Set_filter_type(text)
        set_pagenum(1)

        fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: (1 - 1) * 10,
            limit: 10,
            filter_workerid: filter_workerid,
            filter_chatid: filter_chatid,
            filter_type: text,
            filter_insideid: filter_insideid,
            filter_status: filter_status,
            filter_search_text: filter_search_text,
            from_time: time_from,
            filter_sort: filter_filter,
            to_time: time_to,
            time_offset: new Date().getTimezoneOffset().toString(),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                console.log(data.Allcount)
                settaskscount(data.Allcount)
                settasks(data.Data)
            })

    };

    const handleChangeComments = (e, ticketid) => {
        const {name, value} = e.target;
        // comment_text_dict[ticketid]=value
        console.log(comment_text_dict)
        // Set_comment_text_dict(comment_text_dict)
        Set_comment_text_dict((prev) => ({
            ...prev,
            [ticketid]: value,
        }));
    };

    const onChangePage = (page) => {

        set_pagenum(page)
        UpdateInfoTickets((page-1)*10, undefined, undefined, undefined, undefined,
            undefined, undefined, undefined, undefined, undefined,
            undefined, undefined)
        window.scrollTo(0, 0);
        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (page - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_insideid: filter_insideid,
        //     filter_status: filter_status,
        //     from_time: time_from,
        //     filter_sort: filter_filter,
        //     filter_search_text: filter_search_text,
        //     to_time: time_to,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })

    };

    const onDeleteMessage = (ticketid, commentid) => {


        fetch(Apiurl + "/tickets/del/commentweb?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,
            commentid: commentid

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                var dataedit = tasks
                var foundIndex = dataedit.findIndex(x => x.Ticket_id === ticketid);


                dataedit[foundIndex].Comments = dataedit[foundIndex].Comments.filter((item) => item.Comment_id !== commentid)

                settasks(dataedit)
                console.log(tasks[foundIndex])
                UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                    undefined, undefined, undefined, undefined, undefined,
                    undefined, undefined)
                // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                //     token: localStorage.getItem("token"),
                //     skip: (pagenum - 1) * 10,
                //     limit: 10,
                //     filter_workerid: filter_workerid,
                //     filter_chatid: filter_chatid,
                //     filter_insideid: filter_insideid,
                //     filter_status: filter_status,
                //     from_time: time_from,
                //     filter_search_text: filter_search_text,
                //     filter_sort: filter_filter,
                //     to_time: time_to,
                //     time_offset: new Date().getTimezoneOffset().toString(),
                //
                // }))
                //     .then(response => {
                //         return response.json()
                //     })
                //     .then(data => {
                //         console.log(data.Allcount)
                //         settaskscount(data.Allcount)
                //         settasks(data.Data)
                //     })

            })

    };

    const onSendMessage = (ticketid, comment_text) => {


        fetch(Apiurl + "/tickets/set/commentweb?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticketid: ticketid,
            comment_text: comment_text

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {

                UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                    undefined, undefined, undefined, undefined, undefined,
                    undefined, undefined)
                // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                //     token: localStorage.getItem("token"),
                //     skip: (pagenum - 1) * 10,
                //     limit: 10,
                //     filter_workerid: filter_workerid,
                //     filter_chatid: filter_chatid,
                //     filter_insideid: filter_insideid,
                //     filter_status: filter_status,
                //     from_time: time_from,
                //     filter_sort: filter_filter,
                //     filter_search_text: filter_search_text,
                //     to_time: time_to,
                //     time_offset: new Date().getTimezoneOffset().toString(),
                //
                // }))
                //     .then(response => {
                //         return response.json()
                //     })
                //     .then(data => {
                //         console.log(data.Allcount)
                //         settaskscount(data.Allcount)
                //         settasks(data.Data)
                //
                //         comment_text_dict[ticketid] = ""
                //         console.log(comment_text_dict)
                //         Set_comment_text_dict(comment_text_dict);
                //
                //     })

            })

    };


    const OnOtclickTicket = (ticketid) => {
        console.log(ticketid);

        fetch(Apiurl + "/ticketweb/otclick?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            ticket_id: ticketid,


        }))
            .then(response => {
                return response.json()
            })
            .then(data1 => {
                if (data1.Status) {
                    UpdateInfoTickets((pagenum-1)*10, undefined, undefined, undefined, undefined,
                        undefined, undefined, undefined, undefined, undefined,
                        undefined, undefined)
                    // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
                    //     token: localStorage.getItem("token"),
                    //     skip: (pagenum - 1) * 10,
                    //     limit: 10,
                    //     filter_workerid: filter_workerid,
                    //     filter_chatid: filter_chatid,
                    //     filter_insideid: filter_insideid,
                    //     filter_status: filter_status,
                    //     from_time: time_from,
                    //     filter_search_text: filter_search_text,
                    //     filter_sort: filter_filter,
                    //     to_time: time_to,
                    //     time_offset: new Date().getTimezoneOffset().toString(),
                    //
                    // }))
                    //     .then(response => {
                    //         return response.json()
                    //     })
                    //     .then(data => {
                    //         settaskscount(data.Allcount)
                    //         settasks(data.Data)
                    //         message.success("Вы откликнулись на тикет" + ticketid);
                    //     })
                } else {
                    message.error("Тикет " + ticketid + " не удалось отклкинуться!");
                }
            })


    };

    useEffect(() => {
        fetch(Apiurl + "/ticketweb/clients/get/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_chats_massive(data.Data)
                console.log(data.Data)
            })

        fetch(Apiurl + "/worker/find/all?" + new URLSearchParams({
            token: localStorage.getItem("token"),
            skip: 0,
            limit: 100,

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_workers_massive(data.Data)
                console.log(data.Data)
            })

        fetch(Apiurl + "/users/info?" + new URLSearchParams({
            token: localStorage.getItem("token")

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_users_massive(data.Data)
                console.log(data.Data)
            })

        fetch(Apiurl + "/tickets/get/doptype?" + new URLSearchParams({
            token: localStorage.getItem("token"),

        }))
            .then(response => {
                return response.json()
            })
            .then(data => {
                Set_types_dop_massive(data.Data)
            })

        // fetch(Apiurl + "/tickets/get/type?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         Set_types_massive(data.Data)
        //     })

        UpdateInfoTickets(undefined, undefined, undefined, undefined, undefined,
            undefined, undefined, undefined, undefined, undefined,
            undefined, undefined)
        // fetch(Apiurl + "/ticketweb/tickets/all?" + new URLSearchParams({
        //     token: localStorage.getItem("token"),
        //     skip: (pagenum - 1) * 10,
        //     limit: 10,
        //     filter_workerid: filter_workerid,
        //     filter_chatid: filter_chatid,
        //     filter_type: filter_type,
        //     filter_insideid: filter_insideid,
        //     filter_status: filter_status,
        //     filter_search_text: filter_search_text,
        //     from_time: time_from,
        //     filter_sort: filter_filter,
        //     to_time: time_to,
        //     time_offset: new Date().getTimezoneOffset().toString(),
        //
        // }))
        //     .then(response => {
        //         return response.json()
        //     })
        //     .then(data => {
        //         console.log(data.Allcount)
        //         settaskscount(data.Allcount)
        //         settasks(data.Data)
        //     })
    }, [pagenum]);


    return (<div>
            <Modal
                title="Введите комментарий для закрытия тикета"
                footer={null} open={poup_answer} onCancel={(e) => CloseOpenPopup("")}

            >
                <Flex vertical={true} justify={"center"} gap={"large"}>

                    <TextArea
                        autoSize={{
                            minRows: 3,
                            maxRows: 10,
                        }}
                        onChange={(e) => handleChangeInput(e)}
                        size="large"
                        placeholder="Ваш комментарий"></TextArea>
                    <Button onClick={(e) => Closeticketdope()} type={"primary"}>Отправить</Button>
                </Flex>
            </Modal>
            <Header/>
            <Flex gap={"large"} justify={"center"} align={"center"} style={{paddingTop: "30px"}}>
                <RangePicker disabledTime={disabledRangeTime} format="DD.MM.YYYY HH:mm" showTime
                             onChange={onChangeFilterfilter_date}
                             presets={rangePresets}/>
                <Input
                    style={{width: 200}}
                    placeholder={"Внутренний Id"}
                    onChange={onChangeFilterfilter_insideid}
                >
                </Input>
                <Input
                    style={{width: 200}}
                    placeholder={"Поиск по тексту"}
                    onChange={onChangeFilterfilter_insearchtext}
                >
                </Input>
                <Select
                    style={{width: 200}}
                    placeholder={"Воркер"}
                    allowClear={true}
                    onChange={onChangeFilterWorkerId}
                    options={

                        Object.keys(users_massive).map((chatobject, indexch) => {
                            return {value: chatobject, label: users_massive[chatobject]}
                        })
                    }
                >


                </Select>
                {/*<Select*/}
                {/*    placeholder={"Выберите тип"}*/}
                {/*    style={{width: 150}}*/}
                {/*    onChange={onChangeFilterType_change}*/}
                {/*    allowClear={true}*/}
                {/*    options={*/}

                {/*        types_massive.map((chatobject, indexch) => {*/}
                {/*            return {value: chatobject.Id, label: chatobject.Name}*/}
                {/*        })*/}
                {/*    }*/}
                {/*/>*/}
                <Select
                    placeholder={"Выберите клиента"}
                    style={{width: 150}}
                    onChange={onChangeFilterfilter_chatid}
                    allowClear={true}
                    options={

                        chats_massive.map((chatobject, indexch) => {
                            return {value: chatobject.Id, label: chatobject.Name}
                        })
                    }
                />

                <Select
                    defaultValue="0"
                    style={{width: 150}}
                    onChange={onChangeFilterfilter_status}
                    options={
                        [
                            {value: '0', label: 'Все'},
                            {value: '3', label: 'Закрытые'},
                            {value: '2', label: 'В работе'},
                            {value: '1', label: 'Ожидают ответа'},
                        ]}
                />
                {/*<Button type={"primary"} icon={<FileExcelOutlined/>}*/}
                {/*        rel="noreferrer" htmlType={"a"} target="_blank"*/}
                {/*        href={Apiurl + "/tickets/all/excel?" + new URLSearchParams({*/}
                {/*            token: localStorage.getItem("token"),*/}
                {/*            skip: (1 - 1) * 10,*/}
                {/*            limit: 10,*/}
                {/*            filter_workerid: filter_workerid,*/}
                {/*            filter_chatid: filter_chatid,*/}
                {/*            filter_insideid: filter_insideid,*/}
                {/*            filter_type: filter_type,*/}
                {/*            filter_status: filter_status,*/}
                {/*            filter_search_text: filter_search_text,*/}
                {/*            from_time: time_from,*/}
                {/*            to_time: time_to,*/}
                {/*            time_offset: new Date().getTimezoneOffset().toString(),*/}

                {/*        })}*/}
                {/*>*/}
                {/*    Выгрузить в Excel*/}
                {/*</Button>*/}
                <Checkbox onChange={onChangeFilterfilter_filter}>Сначала старые</Checkbox>
            </Flex>
            <Flex justify={"center"} align={"center"} style={{marginTop: "15px", marginBottom: "15px"}}>
                {alldopclients.length !== 0 && <Select
                    placeholder={"Выберите доп. клиента"}
                    style={{width: 250}}
                    onChange={onChangeFilterfilter_chatid_dop}
                    allowClear={true}
                    options={
                        alldopclients

                    }
                />}
            </Flex>
            <Flex justify={"center"} style={{paddingTop: "10px"}}>

                <div style={{color: "rgba(255, 255, 255, 0.85)"}}>Всего заявок: {taskscount}</div>
            </Flex>
            <Flex vertical={false} justify={"center"} style={{paddingTop: "25px"}}>
                <Space type="flex" size='large' justify="center" align="middle" direction="vertical"
                       style={{padding: "20px"}}>

                    {tasks.map((ticket, index) => (
                        <Card key={ticket.Ticket_id} id={ticket.Ticket_id} title={"ID: " + ticket.Ticket_id}
                              style={{width: "90vw"}} extra={<div>{(ticket.Status === 1) ?
                            <div style={{color: "#ff3c3c"}}>Ожидает ответа</div> : ((ticket.Status === 2) ?
                                <div style={{color: "#ff9201"}}>В работе</div> : (
                                    <div style={{color: "#47ff00"}}>Закрыт</div>))}</div>}>
                            <Flex vertical={true} gap={"small"}>
                                <div><b>Внутренний ID:</b> {ticket.Ticket_id_internal}</div>
                                <div>
                                    <b>Создал:</b> {chats_massive.map((chatobj, index) => ((chatobj.Id === ticket.Client_id) ? chatobj.Name : ""))}
                                </div>
                                <div>
                                    <b>Доп. клиент:</b> {ticket.Client_id_dop!=="" ? ticket.Client_id_dop : "-"}
                                </div>
                                <div style={{paddingTop: "10px"}}></div>
                                <div><b>Создан:</b> {format(new Date(ticket.Ticket_Time_Create), 'dd.MM.yyyy HH:mm:ss')}
                                </div>
                                {localStorage.getItem("admin") === "yes" && ((ticket.Status === 2 || ticket.Status === 3) ?
                                    <div><b>Откликнулся: </b> {users_massive[ticket.Otclick_worker_tgid]}
                                    </div> : "")}
                                {(ticket.Status === 3) ?
                                    <div><b>Закрыт:</b> {format(new Date(ticket.Done_time), 'dd.MM.yyyy HH:mm:ss')}
                                    </div> : ""}

                                <div></div>
                                <div></div>


                                {localStorage.getItem("admin") === "yes" && ((ticket.Status === 2 || ticket.Status === 3) ?
                                    <div><b>Откликнулся
                                        в: </b> {format(new Date(ticket.Otclick_Time), 'dd.MM.yyyy HH:mm:ss')}
                                    </div> : "")}
                                <div></div>
                                {localStorage.getItem("admin") === "yes" && ((ticket.Status === 3) ? <div>
                                    <b>Тип: </b> {types_massive.map((type, index3) => ((type.Id === ticket.Done_ticket_type_id) ? type.Name : ""))}
                                    {/*<div style={{paddingTop: 15}}>*/}
                                    {/*    <b>Доп. тип: </b> <Select*/}
                                    {/*    defaultValue={(ticket.Done_ticket_type_id_dop) ? ticket.Done_ticket_type_id_dop : ""}*/}
                                    {/*    style={{width: 150}}*/}
                                    {/*    onChange={(e) => onChangeDopType_status(e, ticket.Ticket_id)}*/}
                                    {/*    options={*/}
                                    {/*        types_dop_massive.map((chatobject, indexch) => {*/}
                                    {/*            return {value: chatobject.Id, label: chatobject.Name}*/}
                                    {/*        })}*/}
                                    {/*/>*/}
                                    {/*</div>*/}
                                </div> : "")}


                                <Collapse
                                    style={{Width: "100%", marginTop: "15px"}}
                                    items={[{
                                        key: 1,
                                        label: "Основной текст",
                                        children:
                                            <div>{ticket.Message_text}</div>
                                    }
                                    ]}/>

                                {((ticket.Dop_text.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Дополнительный текст",
                                    children:
                                        <Flex vertical={true} gap={"small"}>
                                            {ticket.Dop_text.map((dop_mes, index_dop) => (
                                                <div>{dop_mes}
                                                </div>))}
                                        </Flex>
                                }]}></Collapse> : <div></div>}

                                {((ticket.Close_text.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Сообщения при закрытие",
                                    children:
                                        <Flex vertical={true} gap={"small"}>
                                            {ticket.Close_text.map((dop_mes, index_dop) => (
                                                <div>{dop_mes}
                                                </div>))}
                                        </Flex>
                                }]}></Collapse> : <div></div>}

                                {((ticket.Reopen_text.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Сообщения при возврате",
                                    children:
                                        <Flex vertical={true} gap={"small"}>
                                            {ticket.Reopen_text.map((dop_mes, index_dop) => (
                                                <div>{dop_mes}
                                                </div>))}
                                        </Flex>
                                }]}></Collapse> : <div></div>}

                                {((ticket.Images.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Фото",
                                    children:
                                        <Image.PreviewGroup>
                                            {ticket.Images.map((photos, index_dop) => (
                                                <Image width={100}
                                                       src={Apiurl + "/files/" + photos + "?token=" + localStorage.getItem("token")}/>
                                            ))}
                                        </Image.PreviewGroup>

                                }]}></Collapse> : <div></div>}

                                {((ticket.Documents.length) > 0) ? <Collapse items={[{
                                    key: 1,
                                    label: "Документы",
                                    children:
                                        <Flex vertical={true} gap={"15px"}>
                                            {ticket.Documents.map((document, index_dop) => (
                                                <a target={"_blank"}
                                                   href={Apiurl + "/files/" + document + "?token=" + localStorage.getItem("token")}>
                                                    {document}
                                                </a>
                                            ))}
                                        </Flex>

                                }]}></Collapse> : <div></div>}

                                {(ticket.Status === 1) && <div>
                                    <Divider orientation="left" plain>

                                    </Divider>
                                    <Button onClick={(e) => OnOtclickTicket(ticket.Ticket_id)}
                                            type={"primary"}>Откликнуться</Button>
                                </div>
                                }

                                {(ticket.Status === 2) && <div>
                                    <Divider orientation="left" plain>

                                    </Divider>
                                    <Button onClick={(e) => CloseticketUspeh(ticket.Ticket_id, "1")} type={"primary"}
                                            style={{marginRight: "25px"}}>Успешно</Button>
                                    <Button onClick={(e) => CloseOpenPopup(ticket.Ticket_id)}
                                            style={{marginRight: "25px"}}>Другое</Button>
                                    <Button danger
                                            onClick={(e) => CloseticketUspeh(ticket.Ticket_id, "3")}>Отклонить</Button>
                                </div>
                                }


                                <Divider orientation="left" plain>
                                    Комментарии
                                </Divider>
                                {ticket.Comments !== null && (ticket.Comments.length > 0) && <div>

                                    <Flex vertical={true} style={{width: "40%"}} gap={"middle"}>
                                        {ticket.Comments.map((commentDat, index_dop) => (
                                            <Card style={{borderRadius: "23px"}} bodyStyle={{padding: "10px"}}>
                                                <Flex gap={"3px"}
                                                      style={{textAlign: localStorage.getItem("userid") === commentDat.Userid && "end"}}
                                                      vertical={true}>
                                                    <Text style={{fontSize: "1.2em"}}
                                                          keyboard={true}>{users_massive[commentDat.Userid]}</Text>
                                                    <div style={{fontSize: "1em"}}>{commentDat.Comment_text}</div>
                                                    <Flex align={"center"} justify={"space-between"}>
                                                        {localStorage.getItem("userid") === commentDat.Userid &&

                                                            <Button
                                                                onClick={(e) => onDeleteMessage(ticket.Ticket_id, commentDat.Comment_id)}
                                                                style={{marginLeft: "5px"}} type="primary" danger={true}
                                                                shape="circle" icon={<DeleteOutlined/>}/>

                                                        }
                                                        <Text type={"secondary"}
                                                              style={{fontSize: "0.8em"}}>{format(new Date(commentDat.TimeCreate), 'dd.MM.yyyy HH:mm:ss')}</Text>
                                                    </Flex>
                                                </Flex>

                                            </Card>
                                        ))}
                                    </Flex>
                                </div>}
                                <Search
                                    id={ticket.Ticket_id + "comment"}
                                    style={{marginTop: "15px", width: "40%"}}
                                    placeholder="Оставить комментарий"
                                    allowClear
                                    enterButton={<SendOutlined/>}
                                    value={comment_text_dict[ticket.Ticket_id]}
                                    onChange={(e) => handleChangeComments(e, ticket.Ticket_id)}
                                    size="middle"
                                    onSearch={(e) => onSendMessage(ticket.Ticket_id, e)}
                                />

                                {localStorage.getItem("admin") === "yes" &&

                                    <div>
                                        <Divider/>
                                        <Space
                                            size={"large"}
                                        >

                                            {ticket.Status !== 3 && <Popconfirm
                                                title="Вы уверены что хотите закрыть заявку принудительно ?"
                                                onConfirm={() => {
                                                    confirmclose(ticket.Ticket_id)
                                                }
                                                }
                                                okText="Да"
                                                cancelText="Нет"
                                            >
                                                <Button type={"primary"}>Закрыть заявку принудительно</Button>
                                            </Popconfirm>}

                                            <Popconfirm
                                                title="Удалить заявку ?"
                                                description="Восстановить удаленную заявку неполучится!"
                                                onConfirm={() => {
                                                    confirmdelete(ticket.Ticket_id)
                                                }}
                                                okText="Да"
                                                cancelText="Нет"
                                            >
                                                <Button danger>Удалить заявку</Button>
                                            </Popconfirm>
                                            {ticket.Status === 3 &&
                                                <Button onClick={(e) => reopenticket(ticket.Ticket_id)} danger>Переоткрыть
                                                    заявку</Button>}
                                        </Space>
                                    </div>
                                }
                            </Flex>
                        </Card>
                    ))}
                </Space>


            </Flex>
            <Flex justify={"center"} style={{padding: "45px"}}>
                <Pagination current={pagenum} total={taskscount} showSizeChanger={false} onChange={onChangePage}/>;
            </Flex>


        </div>
    );

}